.vi__container {
    margin: 0 auto;
    direction: ltr;
}

.vi__character {
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    border-color: var(--amplify-components-fieldcontrol-border-color);
    border-width: 2px;
}

.vi__character--selected {
    color: var(--amplify-colors-brand-primary-80);
    border-bottom: 2px solid var(--amplify-colors-brand-primary-80);
    outline: none;
}

@media screen and (max-height: 1030px) {
    .vi__container {
        margin-bottom: 0.5rem;
    }
}
