[data-amplify-router]:has([data-amplify-authenticator-confirmsignin]) {
    padding-bottom: 60px !important;
    position: relative;
}

.counter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}
