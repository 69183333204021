[data-amplify-theme="default-theme"] {
  --amplify-components-authenticator-form-padding: var(--amplify-space-small) !important;
}

[data-amplify-authenticator] {
  position: relative;
}

[data-amplify-container] {
  margin: 40px 0 70px 0;
}

[data-amplify-router] {
  border-radius: 16px;
  padding: var(--amplify-space-large);
}

/* :has is not supported in FF < 120 so field must be disabled
for FF rather then its wrapper */
[type="hiddenField"] {
  display: none;
}

.amplify-field:has([type="hiddenField"]) {
  display: none;
  border-radius: 16px;
  padding: var(--amplify-space-xxl);
}

.amplify-field__show-password {
  border-radius: 0;
  border-color: var(--amplify-components-fieldcontrol-border-color);
  color: var(--amplify-components-fieldcontrol-border-color);
}

.amplify-field__show-password .amplify-icon {
  color: var(--amplify-components-fieldcontrol-border-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 991px) {
  .footer {
    color: #FFF !important;
  }
}

@media screen and (max-height: 1030px) {
  .amplify-heading--3 {
    font-size: var(--amplify-font-sizes-xl);
  }

  .amplify-message__content, .amplify-label, .amplify-input{
    font-size: var(--amplify-font-sizes-xs);
  }

  .amplify-message__heading {
    font-size: var(--amplify-font-sizes-small);
  }

  .amplify-badge {
    font-size: 0.65rem !important;
  }

  .amplify-flex {
    gap: var(--amplify-space-xs);
  }

  .amplify-field-group {
    gap: var(--amplify-components-fieldgroup-gap);
  }

  .amplify-message__content {
    gap: var(--amplify-space-xxxs);
  }
}

@media screen and (max-height: 900px) {
  [data-amplify-router] {
    padding-top: var(--amplify-space-xxxs);
    padding-bottom: var(--amplify-space-xxxs);
  }

  [data-amplify-container]:has([data-amplify-authenticator-confirmresetpassword]) {
    margin-top: 10px;
  }

  [data-amplify-authenticator-confirmresetpassword] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 690px) {
  [data-amplify-router] {
    padding-left: var(--amplify-space-xxxs);
    padding-right: var(--amplify-space-xxxs);
    width: 95%;
    margin: 0 auto;
  }
  .footer {
    width: 100% !important;
    left: 0;
  }

  [data-amplify-container] {
    width: 100% !important;
  }
}
